import Bugsnag from '@bugsnag/js';
import { Mixpanel } from '../utils';
import storage from '../utils/storage';

export const validMessageTypes = [
  'INSUFFICIENT_FUNDS',
  'DO_NOT_HONOR',
  'FRAUDULENT_TRANSACTION',
  'NOT_ENROLLED',
  'WITHDRAWAL_LIMIT',
  'REFER_TO_ISSUER',
  'NO_CARD_RECORD',
  'INVALID_CARD_DETAILS',
  'CHARGE_PREVENTED',
  'PIN_RETRIES_EXCEEDED',
  'CARD_EXPIRED',
  'NOT_COMPLETED',
  'AMOUNT_EXCEEDED',
  'PAYMENT_EXPIRED',
  'ISSUER_INOPERATIVE'
];

export const failed = (errorMessage, state) => ({
  ...state,
  loader: false,
  modalState: 'failed',
  outgoingStatus: 'failed',
  page: 'Payment failed',
  feedback: true,
  feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-failed.svg',
  buttonAction: 'otherOptions',
  isOtpIncorrect: false,
  isPINIncorrect: false,
  card: {
    stage: 'init',
    token_id: '',
    redirectUrl: '',
    cardType: ''
  },
  mobileMoney: {
    stage: 'mobile_money'
  },
  outgoingError: errorMessage,
  errorMessage
});

/**
 * Generates a failed message that serves the card init form
 * @param  {Object} response
 * @param  {Object} state
 * @return {state}
 */
export const failedCardStatus = (response, state) => {
  const response_message = response?.response_message || response?.data?.for_customer ? response.message : '';
  const error_type = response?.error_type || '';
  const status = response?.status;

  if (validMessageTypes.includes(error_type)) {
    Mixpanel.track('Payment failed', {
      'Reason for failure': response_message || 'Card could not be charged due to an unknown error'
    });
    return {
      ...state,
      loader: false,
      buttonAction: 'changePayment',
      outgoingStatus: 'failed',
      isCardDetailsIncorrect: true,
      errorMessage: response_message,
      card: {
        ...state.card,
        stage: 'init',
        cardType: ''
      }
    };
  } else if (error_type === 'INCORRECT_PIN') {
    if (status === 'failed') {
      Mixpanel.track('Payment failed', {
        'Reason for failure': response_message || 'Card could not be charged due to an invalid PIN'
      });
    }
    return {
      ...state,
      loader: false,
      buttonAction: 'changePayment',
      outgoingStatus: 'failed',
      isCardDetailsIncorrect: true,
      errorMessage: response_message,
      card: {
        ...state.card,
        stage: 'init',
        cardType: ''
      }
    };
  } else if (error_type === 'INVALID_OTP') {
    if (status === 'failed') {
      Mixpanel.track('Payment failed', {
        'Reason for failure': response_message || 'Card could not be charged due to an invalid OTP'
      });
    }
    return {
      ...state,
      loader: false,
      buttonAction: 'changePayment',
      outgoingStatus: 'failed',
      isCardDetailsIncorrect: true,
      errorMessage: response_message,
      card: {
        ...state.card,
        stage: 'init',
        cardType: ''
      }
    };
  }
  // All other errors (UNKNOWN_ERROR)
  Mixpanel.track('Payment failed', {
    'Reason for failure': response_message || 'Card could not be charged due to an unknown error'
  });
  return {
    ...state,
    loader: false,
    buttonAction: 'changePayment',
    isCardDetailsIncorrect: true,
    outgoingStatus: 'failed',
    errorMessage: response_message, // Use default error message
    card: {
      ...state.card,
      stage: 'init',
      cardType: ''
    }
  };
};

export default function errorReducer(type, response, state) {
  const errorMessage = response?.message || null;
  const datas = response?.data;
  const customError = datas && datas[Object.keys(datas)[0]]?.message;
  switch (type) {
    case 'GET_TRANSACTION': {
      return {
        ...failed('There seems to be an issue with verifying the payment information.', state),
        feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
        modalState: 'Something’s wrong',
        buttonAction: 'close'
      };
    }
    case 'CONFIRM_PAYMENT_LINK': {
      return {
        ...failed('There has been an error resolving this payment link. Please confirm that it is not incomplete or wrong.', state),
        feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon_link.svg',
        modalState: 'Something’s wrong',
        buttonAction: 'close'
      };
    }
    case 'INIT_CHARGE_PAYMENTLINK': {
      return {
        ...failed(errorMessage, state),
        errorMessage: `Sorry, we were unable to process this transaction. Please contact the business you are attempting to pay for help`,
        outgoingError: customError ? customError : errorMessage,
        isSelfClosing: true,
        buttonAction: 'none'
      };
    }
    case 'INIT_CHARGE_BANKTRANSFER': {
      if (errorMessage?.includes('An error occurred while generating account number, please try again')) {
        Bugsnag.notify('Error in generating virtual account number');
        return failed(errorMessage, state);
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'AUTH_CHARGE_MOBILE_MONEY': {
      if (response?.response_message.includes('invalid')) {
        return {
          ...state,
          loader: false,
          isOtpIncorrect: true,
          errorMessage
        };
      }
      if (errorMessage?.includes('Token expired please generate another token')) {
        return {
          ...state,
          loader: false,
          isOtpIncorrect: true,
          errorMessage: 'The OTP token has expired. Please try a requst for another one'
        };
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'RESEND_CHARGE_MOBILE_MONEY':
    case 'INIT_CHARGE_MOBILE_MONEY': {
      if (errorMessage?.includes('Payment has already been completed')) {
        return {
          ...failed('This charge has already been completed. Please try a new transaction', state)
        };
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'QUERY_MOBILE_MONEY_EGANOW': {
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'INIT_CHARGE_BANK_DEBIT': {
      if (errorMessage?.includes('Source account enquiry failed')) {
        Mixpanel.track('User inputted wrong account number');
        Bugsnag.notify('User inputted wrong account number');
        return {
          ...state,
          errorMessage
        };
      }
      if (errorMessage?.includes('Payment has been completed')) {
        return {
          ...failed('This charge has already been completed. Please try a new transaction', state)
        };
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'REDIRECT_URL': {
      if (errorMessage?.includes('Payment is already completed')) {
        return {
          ...failed('This charge has already been completed. Please try a new transaction', state),
          buttonAction: 'none',
          modalState: 'issue detected',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg'
        };
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'AUTHORIZE_BANK_DEBIT': {
      if (errorMessage?.includes('OTP is invalid') || errorMessage?.includes('Invalid PayId')) {
        Mixpanel.track('User inputted wrong OTP (PWB)');
        Bugsnag.notify('User inputted wrong OTP (PWB)');
        return {
          ...failed(errorMessage, state)
        };
      }
      if (errorMessage?.includes('Payment charge completed')) {
        return {
          ...failed('This charge has already been completed. Please try a new transaction', state)
        };
      }
      return {
        ...failed(errorMessage, state)
      };
    }
    case 'INIT_CHARGE_CARD': {
      return failedCardStatus(response, state);
    }
    case 'AUTH_VERIFY_CARD_PIN':
    case 'AUTH_VERIFY_CARD_OTP':
    case 'AUTH_VERIFY_CARD_AVS':
    case 'AUTH_VERIFY_CARD_PHONE':
    case 'QUERY_THREEDS_TYPE': {
      return failedCardStatus(response, state);
    }
    case 'QUERY_CARD_CHARGE': {
      return state;
    }
    case 'VERIFY_CARD_CHARGE': {
      if (errorMessage?.includes('Transaction not found')) {
        return {
          ...failed('Unable to verify this transaction. Please contact support.', state),
          buttonAction: 'none',
          loader: false
        };
      }
      return {
        ...failed('Sorry, your payment was not completed.', state),
        buttonAction: 'none',
        loader: false
      };
    }
    case 'GET_SAVEDCARDS': {
      if (response?.data?.error_type === 'NO_CARD_RECORD') {
        storage.deleteItem('titan', { email: state.customer.email, id: state.paymentLink?.merchant_id });
        return {
          ...state,
          card: {
            stage: 'init'
          },
          isCardDetailsIncorrect: true,
          errorMessage: response.message,
          loader: false,
          buttonAction: 'changePayment',
          hideHeading: false
        };
      }
      return {
        ...state,
        card: {
          stage: 'init'
        },
        isCardDetailsIncorrect: true,
        errorMessage: response.message,
        loader: false,
        buttonAction: 'changePayment',
        hideHeading: false
      };
    }
    case 'VERIFY_THREEDS_TYPE': {
      return {
        ...failed('An error occurred while redirecting you to your authorization page, please try again', state),
        buttonAction: 'none',
        modalState: 'Something’s wrong',
        loader: false
      };
    }
    case 'QUERY_TRANSACTION':
    case 'CONFIRM_MOBILE_MONEY':
    case 'CONFIRM_BANKTRANSFER':
    case 'CONFIRM_PAYWITHBANK': {
      const status = response?.status;
      if (status === 'expired') {
        return {
          ...state,
          loader: false,
          feedback: true,
          errorMessage: 'This transaction has expired. Please try a new transaction',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
          modalState: 'issue detected',
          issueStatus: status,
          buttonAction: 'changePayment'
        };
      }
      return {
        ...failed(errorMessage, state),
        buttonAction: 'none'
      };
    }
    case 'AUTH_THREEDS_TEST': {
      return {
        ...state,
        loader: false
      };
    }
    default:
      return failed(errorMessage, state);
  }
}
