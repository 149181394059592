export const bugsnagWhitelistErrors = [
  "Cannot read properties of undefined (reading 'showWidget')",
  "Cannot read properties of undefined (reading 'setContainerStyle')",
  "Cannot read properties of undefined (reading 'setJwt')",
  "Cannot read properties of undefined (reading 'setHostConfig')",
  'User inputted a wrong link',
  'Request failed with status code 409',
  'Request failed with status code 403',
  'Request failed with status code 400'
];
