import React, { useState, useEffect, useRef, useLayoutEffect, Dispatch } from 'react';
import { modalStore } from '../../../state';
import {
  checkCard,
  formatCardNumber,
  formatDate,
  formatAmount,
  extractDate,
  formatNumber,
  formatText,
  removeEmptySpaces,
  validateCardNumber,
  validateCardCvv,
  validateCardExpiryDate,
  autoErrorFocus,
  getFieldError
} from '../../../utils/formats';

import InputToolTip from '../../shared/input-tooltip';
import lock from '../../../assets/lock.svg';
import cvvmessage from '../../../assets/cvv-message.svg';
import { cardDetails } from '../cardtypes';
import cardInfo from '../../../assets/card-info.svg';

interface CardInitType {
  cardDetails: cardDetails;
  setCardDetails: Dispatch<React.SetStateAction<cardDetails>>;
  chargeCard: (e: string) => Promise<void>;
  resetCardStage: () => void;
  onChecked: (e: boolean) => void
  refetchConversionRates: ()=> void
}

const cardMsg = process.env.VITE_APP_CARD_ALERT_MSG || '';

const CardInitForm = ({ cardDetails, setCardDetails, chargeCard, resetCardStage, onChecked , refetchConversionRates}: CardInitType) => {
  const { testCard, currency, amount, fees, update, isCardDetailsIncorrect, errorMessage, paymentLink, browser_card_token, payment_channels_details, exchangeRateExpired } =
    modalStore();
  useLayoutEffect(() => {
    update({ page: 'Card Details' });
  }, []);

  const [formatted, setFormatted] = useState({
    card: '',
    date: '',
    cardType: '',
    cardHolder: ''
  });
  const [isChecked, setIsChecked] = useState(false);
  const submitButtonRef = useRef<null | HTMLButtonElement>(null);
  const cardIconRef = useRef<null | HTMLDivElement>(null);
  const newCardSessionRef = useRef(false);

  if (testCard) {
    setTimeout(() => {
      checkCard(testCard.number, cardIconRef.current);
      validateCardNumber(testCard.number);
      validateCardExpiryDate(`${testCard.expiry_month}/${testCard.expiry_year.slice(-2)}`);
      validateCardCvv(testCard.cvv);
    }, 100);
  }

  const handleDisabled = () => {
    if (exchangeRateExpired) return false
    if (testCard) return false;
    if (cardDetails.cvv.length !== 3) return true;
    if (!validateCardNumber(cardDetails.number)) return true;
    if (!validateCardExpiryDate(formatted.date)) return true;
    if (currency === 'KES' && cardDetails?.name && cardDetails?.name.length < 2) return true;
  };

  const handleRedirectToSavedCards = () => {
    update({
      buttonAction: 'savedCard',
      card: {
        stage: 'savedcards'
      },
      hideHeading: true
    });
  };

  const checkHandler = () => {
    setIsChecked(!isChecked);
    onChecked(!isChecked);
  };

  const submitCardInitForm = useRef(e => {
    // Enter key triggers the payment
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton?.disabled) {
        submitButton?.click();
      }
      return;
    }
  });

  const resetErrorMessage = useRef(() => {
    update({
      isCardDetailsIncorrect: false,
      isOtpIncorrect: false,
      isPINIncorrect: false,
      errorMessage: ''
    });
    newCardSessionRef.current = false;
  });

  useEffect(() => {
    const submitCardInit = submitCardInitForm.current;
    window.addEventListener('keydown', submitCardInit);
    return () => window.removeEventListener('keydown', submitCardInit);
  }, []);

  useEffect(() => {
    if (isCardDetailsIncorrect) {
      const cardIcon = cardIconRef.current as HTMLDivElement;
      cardIcon.innerHTML = '';
      cardIcon.classList.remove('visible');
      setFormatted({
        card: '',
        date: '',
        cardType: '',
        cardHolder: ''
      });
      resetCardStage();
      setTimeout(() => document.querySelector('.details-error')?.focus());
      setTimeout(() => (newCardSessionRef.current = true), 2000);
    }
  }, [isCardDetailsIncorrect, resetCardStage]);

  useEffect(() => {
    if (isCardDetailsIncorrect && newCardSessionRef.current) {
      resetErrorMessage.current();
    }
  }, [cardDetails, isCardDetailsIncorrect]);
  return (
    <div className="kpy-col__pay-form">
      <p className="details-head">Enter your card information to complete this payment</p>
      {isCardDetailsIncorrect && (
        <p className="details-error" tabIndex={-1}>
          {errorMessage || 'There was an error while processing your payment. Please try again later.'}
        </p>
      )}
      {cardMsg && (
        <div className="bank-card-warning">
          <div>
            <img src={cardInfo} alt={cardMsg} />
            <span aria-label={cardMsg}>{cardMsg}</span>
          </div>
        </div>
      )}
      <section className="channel_container">
        {currency === 'KES' && (
          <div className="kpy-col-input-field">
            <label htmlFor="card-holder-name" className="kpy-col-label">
              Card holder name
            </label>
            <div className="field-wrapper">
              <input
                aria-label="card-holder-name"
                kpy-name-input="true"
                className={'kpy-col-input'}
                id="card-holder-name"
                name="card-holder-name"
                type="text"
                placeholder="John Doe"
                value={formatted.cardHolder}
                minLength={2}
                maxLength={40}
                onChange={e => {
                  setFormatted({
                    ...formatted,
                    cardHolder: formatText(e.target.value)
                  });
                  setCardDetails({
                    ...cardDetails,
                    name: removeEmptySpaces(formatText(e.target.value))
                  });
                }}
              />
            </div>
          </div>
        )}

        <div className="kpy-col-input-field">
          <label htmlFor="card-number" className="kpy-col-label">
            Card number
          </label>
          <div className="field-wrapper">
            <input
              aria-label="card-number"
              kpy-card-input="true"
              data-testid="card-number"
              className={`kpy-col-input --card-details ${
                cardDetails.number.length > 0 && !validateCardNumber(cardDetails.number) && 'error-input'
              }`}
              id="card-number"
              name="card-number"
              type="text"
              aria-labelledby={cardDetails.number.length > 0 && !validateCardNumber(cardDetails.number) ? 'card-number-error' : ''}
              placeholder="0000 0000 0000 0000"
              inputMode="numeric"
              readOnly={testCard}
              value={testCard ? formatCardNumber(testCard.number) : formatted.card}
              maxLength={23}
              onChange={e => {
                let validCard;
                let cardType = '';
                const formattedNumber = formatNumber(e.target.value);
                if (e.target.value.length === 0) {
                  cardType = checkCard(e.target.value, cardIconRef.current);
                }
                if (e.target.value.length >= 10) {
                  cardType = checkCard(e.target.value, cardIconRef.current);
                  validCard = validateCardNumber(e.target.value);
                }
                if (validCard && formattedNumber.length === 16 && cardType !== 'verve') {
                  (document.querySelector('[kpy-date-input]') as HTMLInputElement)?.focus();
                }
                if (validCard && formattedNumber.length === 19 && cardType === 'verve') {
                  (document.querySelector('[kpy-date-input]') as HTMLInputElement)?.focus();
                }
                setFormatted({
                  ...formatted,
                  card: formatCardNumber(e.target.value),
                  cardType
                });
                setCardDetails({
                  ...cardDetails,
                  number: formattedNumber
                });
              }}
            />
            <div className="ccicon" ref={cardIconRef} />
          </div>
          <p id="card-number-error" className="sr-only">
            {getFieldError('card-number')}
          </p>
          {((cardDetails.number.length >= 16 && formatted.cardType !== 'verve') ||
            (cardDetails.number.length >= 19 && formatted.cardType === 'verve')) &&
            !validateCardNumber(cardDetails.number) && (
              <p className="kpy-input-field-error card-number-error">{getFieldError('card-number')}</p>
            )}
        </div>

        <div className="second_card_layer">
          <div className="kpy-col-input-field --mb-0">
            <label htmlFor="card-expiry" className="kpy-col-label">
              Expiry Date
            </label>
            <input
              aria-label="card-expiry"
              data-testid="card-expiry"
              kpy-date-input="true"
              id="card-expiry"
              aria-labelledby={formatted.date.length > 0 && !validateCardExpiryDate(formatted.date) ? 'card-expiry-error' : ''}
              className={`kpy-col-input --card-details date_input ${
                formatted.date.length > 0 && !validateCardExpiryDate(formatted.date) && 'error-input'
              }`}
              name="valid-till"
              type="text"
              pattern="^[0-9\/]+$"
              readOnly={testCard}
              value={testCard ? `${testCard.expiry_month}/${testCard.expiry_year.slice(-2)}` : formatted.date}
              placeholder="MM/YY"
              inputMode="numeric"
              maxLength={5}
              onChange={e => {
                const formattedDate = formatDate(e.target.value);
                const validExpiry = validateCardExpiryDate(formattedDate);
                if (formattedDate.length > 4 && validExpiry) {
                  (document.querySelector('[kpy-date-input]') as HTMLInputElement)?.focus();
                }
                setFormatted(prevFormatted => ({
                  ...prevFormatted,
                  date: formattedDate
                }));
                setCardDetails({
                  ...cardDetails,
                  expiry_month: extractDate(formattedDate, 1),
                  expiry_year: extractDate(formattedDate, 2)
                });
              }}
              onKeyDown={e => {
                // Clear '/' when the first number of the year is cleared
                if (e.keyCode === 8 && formatted.date.length === 4) {
                  setTimeout(
                    () =>
                      setFormatted(prevFormatted => ({
                        ...prevFormatted,
                        date: prevFormatted.date.slice(0, prevFormatted.date.length - 1)
                      })),
                    50
                  );
                }
              }}
            />
            <p id="card-expiry-error" className="sr-only">
              {getFieldError('card-expiry')}
            </p>
            {formatted.date.length >= 5 && !validateCardExpiryDate(formatted.date) && (
              <p className="kpy-input-field-error card-expiry-error">{getFieldError('card-expiry')}</p>
            )}
          </div>
          <div className="kpy-col-input-field">
            <label htmlFor="card-cvv" className="kpy-col-label">
              CVV
            </label>
            <div className="field-wrapper">
              <input
                aria-label="card-cvv"
                data-testid="card-cvv"
                kpy-cvv-input="true"
                id="card-cvv"
                aria-labelledby={cardDetails.cvv.length > 0 && validateCardCvv(cardDetails.cvv) ? 'card-cvv-error' : ''}
                className={`kpy-col-input --card-details cvv-input ${
                  cardDetails.cvv.length > 0 && !validateCardCvv(cardDetails.cvv) && 'error-input'
                }`}
                name="cvv"
                type="text"
                readOnly={testCard}
                value={testCard ? testCard.cvv : cardDetails.cvv}
                placeholder="000"
                maxLength={3}
                inputMode="numeric"
                onChange={e => {
                  setCardDetails({
                    ...cardDetails,
                    cvv: formatNumber(e.target.value)
                  });
                }}
                onKeyDown={e => {
                  if (e.shiftKey && e.keyCode === 9) {
                    return;
                  }
                  // Tabbing forwards should focus on the pay button if there are no errors
                  if (e.keyCode === 9 && cardDetails.cvv.length === 3 && validateCardCvv(cardDetails.cvv)) {
                    setTimeout(() => {
                      const submitButton = submitButtonRef.current;
                      if (!submitButton?.disabled) {
                        submitButton?.focus();
                        return;
                      }
                      autoErrorFocus(['card-number', 'card-expiry', 'card-cvv']);
                    }, 500);
                  }
                }}
              />
              <InputToolTip
                type="card-cvv"
                message={
                  <p className="centered" id="card-cvv-input-tooltip">
                    Your security code is the <strong>3 or 4-digit</strong> number on the back of your card.
                  </p>
                }
                image={
                  <>
                    <img src={cvvmessage} alt="card-cvv illustration" aria-hidden />
                  </>
                }
              />
            </div>
            <p id="card-cvv-error" className="sr-only">
              {getFieldError('card-cvv')}
            </p>
            {cardDetails.cvv.length >= 3 && !validateCardCvv(cardDetails.cvv) && (
              <p className="kpy-input-field-error card-cvv-error">{getFieldError('card-cvv')}</p>
            )}
          </div>
        </div>
        {payment_channels_details?.card?.can_save_card && formatted.cardType === 'mastercard' && (
          <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
            <input style={{ cursor: 'pointer' }} type="checkbox" checked={isChecked} onChange={checkHandler} />
            <div style={{ paddingBottom: '0px', marginLeft: '5px' }} className="kpy-col-label">
              <p style={{ fontWeight: 500, fontSize: '12px' }}>Save Card for future payment</p>
              <a href="https://developers.korapay.com/" target="_blank">
                Learn More
              </a>
            </div>
          </div>
        )}
      </section>

      <button
        className="kpy-col-btn card-init-form-btn"
        onClick={() => {
          const { cardType } = formatted;
          if (exchangeRateExpired){
            refetchConversionRates();
            return;
          }
          if (isCardDetailsIncorrect && newCardSessionRef.current) {
            resetErrorMessage.current();
          }
          chargeCard(cardType);
        }}
        ref={submitButtonRef}
        disabled={handleDisabled()}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
      >
        <img src={lock} alt="lock icon" aria-hidden />
        {exchangeRateExpired ? <span>Proceed</span> : <span>
          Pay {currency} {formatAmount(parseFloat(amount) + parseFloat(fees?.card?.fee || 0) + parseFloat(fees?.card?.vat || 0))}
        </span>}
      </button>

      {browser_card_token && (
        <button onClick={handleRedirectToSavedCards} style={{ color: '#2476f3' }} className="kpy-feedback-btn">
          <span>Use saved card</span>
        </button>
      )}
    </div>
  );
};

export default CardInitForm;
