import { modalStore } from '../../state';
import Copyable from './copyable';
import TimerIcon from '../../assets/timericon.svg'
import { formatAmount, switchPaymentChannelImage, switchPaymentChannelImageType, calculateTime, switchCountryCurrency} from '../../utils';
import { useEffect, useRef, useState } from 'react';

export default function Heading() {
  const timerInterval = useRef<null | NodeJS.Timeout>(null);
  const { avatar, amount, currency, hideHeading, stage, fees, headingImage, update, conversion, conversionRateUpdated, exchangeRateErrorMessage, exchangeRateExpired } = modalStore();
  const [time, setTime] = useState(calculateTime(conversion?.expiryTime));

  useEffect(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }

    if (time.text === "expired") {
      update({
        exchangeRateErrorMessage: 'This transaction expired because the currency rate changed. Click proceed to continue with the new rate',
        exchangeRateExpired: true
      })
    }

    const startTime = new Date();
    timerInterval.current = setInterval(() => {
      const timerValue = calculateTime(conversion?.expiryTime, startTime);
      return setTime(timerValue);
    }, 1000);

    return () => clearInterval(timerInterval.current);
  }, [conversion?.expiryTime, time.text]);

   useEffect(() => {
    if (conversionRateUpdated) {
      setTimeout(() => update({
        exchangeRateExpired: false,
      }), 1);
       setTimeout(() => update({
        conversionRateUpdated: false,
      }), 5000);
    }
  }, [conversionRateUpdated]);


  return (
    <>
      <section className="kpy-col__header" id="kpy-col__header" hidden={hideHeading}>
        {!['pay_with_bank', 'bank_transfer', 'card', 'mobile_money'].includes(stage) ? (
          avatar && (
            <div className="merchant-logo">
              <img src={avatar} alt="merchant-logo" />
            </div>
          )
        ) : (
          <div className="stage-heading">
            <img src={headingImage || switchPaymentChannelImage()[stage as keyof switchPaymentChannelImageType][0]} alt={`${stage} icon`} />
          </div>
        )}

        <h1>
          Pay {currency} {formatAmount(parseFloat(conversion?.payment_amount || amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}
        </h1>
        {stage === 'bank_transfer' && (
          <Copyable
            spanClassName={'bt_copy_amount'}
            textModifier={() => 'Tap to copy amount'}
            showIcon={false}
            text={formatAmount(parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}
          />
        )}
        {conversion && <div className='currency-exchange-rate'>
          <p className='exchange'>{`${switchCountryCurrency(conversion?.currency)}1 = ${switchCountryCurrency(conversion.payment_currency)}${formatAmount(conversion?.rate)} For`}</p>
          <p className='timer'>
            <img height={'20px'} src={TimerIcon} alt="" /> <span style={{color: time.text==="expired" ? '#F32345': ''}}>{time.text === "expired" ? '0:00' : time.text}</span>
          </p>
        </div>
        }
        {conversionRateUpdated && 
          <div className='conversion-rate-updated'>
            <p>The amount to pay has been updated according to the new currency rate.</p>
          </div>
        }
        {exchangeRateExpired && 
          <div className="expired-exchange-rate">
            <p className="details-error" tabIndex={-1}>
              {exchangeRateErrorMessage}
            </p>
          </div>
        }
      </section>
    </>
  );
}
